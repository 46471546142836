import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const BlogPage = ({ blogs }) => {
  return (
    <div className="w-4/5 mx-auto mt-10 lg:mt-20">
      <h1 className="pt-12 mb-[82px] text-gray_900 fontFamily2 font-bold text-[42px] leading-[51px]">
        HighKey Health & Wellness Blog
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-[80px] lg:mb-[128px] gap-10">
        {blogs?.map(
          ({ author: { name }, featuredImage, title, slug: { current } }) => {
            return (
              <Link to={`/${current}`}>
                <div className="w-full ">
                  <GatsbyImage
                    image={featuredImage?.asset?.gatsbyImageData}
                    loading="lazy"
                    className="w-full  lg:h-[216px] 2xl:h-[276px]"
                    alt="card-img"
                  />
                  <h6 className="mt-2 text-gray_300 fontFamily2 mb-2 text-sm leading-[18px] font-semibold">
                    {name}
                  </h6>
                  <p className="fontFamily2 text-xl text-gray_900 leading-[30px]">
                    {title}
                  </p>
                </div>
              </Link>
            );
          }
        )}
      </div>
    </div>
  );
};

export default BlogPage;
