import * as React from "react";
import BlogPage from "../components/BlogPage";
import Layout from "../components/Layout";
import BookingSection from "../components/BookingSection";
import Seo from "../components/SEO";
import { graphql } from "gatsby";

const META_DATA = {
  title: "Health & Wellness Blog | Wellness & Recovery | HighKey",
  description: `Get great insights and tips from the health experts at Washington D.C.'s HighKey Balance. Visit our blog for the latest in wellness and recovery.`,
};

const IndexPage = ({ data }) => {
  const blogs = data?.blogs?.nodes
  return (
    <Layout>
      <Seo {...META_DATA} />
      <BlogPage blogs={blogs} />
      <BookingSection />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query GetAllBlogs {
    blogs: allSanityBlogs {
      nodes {
        title
        slug {
          current
        }
        featuredImage {
          asset {
            gatsbyImageData
            url
          }
        }
        author {
          name
        }
      }
    }
  }
`;
